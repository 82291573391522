import React from "react";
import { Layout } from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <h2>Nothing Here</h2>
    <p>Check that you followed the correct address.</p>
  </Layout>
);

export default NotFoundPage;
